<template>
  <el-radio-group v-model="used" v-bind="$attrs" v-on="$listeners">
    <el-radio-button
      v-for="item in list"
      :key="item.value"
      :label="item.value"
      >{{ item.title || item.label }}</el-radio-button
    >
  </el-radio-group>
</template>

<script>
export default {
  name: "MfFilter",

  props: {
    list: {
      required: true,
      type: Array,
    },

    value: {
      required: true,
      type: String,
    },
  },

  computed: {
    used: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-radio-button:first-child .el-radio-button__inner {
    border-left: 0;
    border-radius: 12px 0 0 12px;
  }

  .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 12px 12px 0;
  }

  .el-radio-button__inner {
    height: 40px;
    background: #f5f5f5;
    color: #6e777f;
    border: 0;
    display: flex;
    align-items: center;
  }
}
</style>
