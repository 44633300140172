import Vue from 'vue'
import ImageClipper from './ImageClipper'

const ImageClipperConstructor = Vue.extend(ImageClipper)
let instance

const Proto = function (options) {
  return new Promise((resolve, reject) => {
    instance = new ImageClipperConstructor()
    instance.$mount()
    document.body.appendChild(instance.$el)
    instance.showClipper = true
    instance.src = options.src
    instance.ratio = options.ratio
    instance.filename = options.filename
    instance.confirm = (file) => {
      resolve(file)
      instance.showClipper = false
    }
    instance.cancel = () => {
      resolve(options.file)
      instance.showClipper = false
    }
  })

}

export default Proto
