import { getLayout } from '@/utils/auth'
import LiveLayout from './live'

/**
 * 标签页经典后台管理系统布局
 * @param Vue
 */
LiveLayout.install = function(Vue) {
  Vue.component(LiveLayout.name, LiveLayout)
}

/**
 * 布局切换
 * @returns {*}
 */
function getRuntimeLayout() {
  let layout = null
  switch ('3') {
    case '3':
      layout = LiveLayout
      require('@/styles/live/index.scss')
      break
  }
  return layout
}
export default getRuntimeLayout()

