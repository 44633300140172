import { JSEncrypt } from "jsencrypt";

export default {
  async install(Vue) {
    let jsEncrypt = null

    Vue.prototype.$encrypt = async function (str) {
      if (!str || typeof str !== 'string') return ""

      // if (!jsEncrypt) {
      //   const publicKey = (await getPublickey()).body
      //   jsEncrypt = new JSEncrypt()

      //   jsEncrypt.setPublicKey(publicKey);
      // }

      return jsEncrypt.encrypt(str)
    }

    Vue.prototype.$resetEncrypt = function () {
      jsEncrypt = null
    }
  }
}
