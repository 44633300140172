import common from "@/api/common";

async function upload(str) {
  if (str === "" || /^https?\:\/\//.test(str)) {
    return Promise.resolve(str)
  } else {
    const res = await common.singleUploadBase({
      base64FileStr: str
    })

    return Promise.resolve(res.body)
  }
}

export default {
  async install(Vue) {
    Vue.prototype.$imageUpload = function (option) {
      console.log(option)
      return new Promise(async (resolve, reject) => {
        try {
          if (typeof option === 'string') {
            const result = await upload(option)

            resolve(result)
          } else if (Array.isArray(option)) {
            const result = []

            for (let i = 0; i < option.length; i++) {
              const s = await upload(option[i])
              result.push(s)
            }

            resolve(result)
          } else {
            resolve(option)
          }
        } catch (e) {
          console.error(e)
          reject(e)
        }
      })
    }
  }
}
