import request from '@/utils/request'

export default {
  singleUpload(data) {
    return request({
      url: '/upload/file',
      method: 'post',
      data
    })
  },

  singleUploadBase(data) {
    return request({
      url: '/api/live/brand/file/singleUploadBase',
      method: 'post',
      data
    })
  },

  multipleUploadBase(data) {
    return request({
      url: '/api/live/brand/file/multipleUploadBase',
      method: 'post',
      data
    })
  }
}
