<template>
  <div :class="{ 'has-logo': showLogo }" style="height: 100%">
    <logo v-if="showLogo" :collapse="isCollapse" @toggle="toggle" />
     {{  dynamicRouters  }}
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        ref="menu"
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#FFFFFF"
        text-color="#7A7C88"
        active-text-color="#252631"
        unique-opened
        :collapse-transition="false"
        mode="vertical"
        @select="selectSidebar"
      >
        <sidebar-item
          v-for="route in dynamicRouters"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";

export default {
  name: "Sidebar",

  data() {
    return {
      selectedIndex: ""
    };
  },

  computed: {
    ...mapGetters(["sidebar", "dynamicRouters"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },

    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },

    isCollapse() {
      return !this.sidebar.opened;
    },

    // 所有的路由信息
    dynamicRouters() {
      return this.$store.state.permission.topbarRouters
    }
  },

  methods: {
    toggle() {
      this.$store.commit("app/TOGGLE_SIDEBAR", !this.isCollapse);
    },

    selectSidebar(index, indexPath) {
      const openedMenu = this.$refs["menu"].openedMenus[0] || "";

      if (openedMenu && !indexPath.includes(openedMenu)) {
        console.log("close");
        this.$refs["menu"].close(openedMenu);
      }
    },
  },

  components: { SidebarItem, Logo },
};
</script>

<style lang="scss" scoped>
::v-deep .el-menu--collapse .submenu-title-noDropdown,
::v-deep .el-menu--collapse .el-submenu__title {
  span {
    width: 0;
    overflow: hidden;
  }

  .menu-icon {
    margin: 0 !important;
    flex: 1;
  }
}
::v-deep {
  .el-submenu__icon-arrow {
    transform: rotate(-90deg);
    font-weight: 700;
    color: #9799A4;
  }
  .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    transform: rotate(0deg);
    color: #000;
  }
}
</style>
