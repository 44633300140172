<template>
  <mf-dialog
    :visible.sync="show"
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    width="550px"
    :on-confirm="onConfirm"
    :on-cancel="onCancel"
    @close="clearFiles"
  >
    <el-upload
      ref="uploader"
      class="flex column"
      :class="{ list: showList }"
      drag
      action="#"
      accept=".xls, .xlsx"
      :auto-upload="false"
      :multiple="false"
      :on-exceed="() => $message.error('只能选择一个文件')"
      :on-change="handleChange"
      :on-remove="handleChange"
    >
      <div class="upload-text">
        <svg-icon class="icon" icon-class="icon-import" />
        <span class="text">{{ showList ? "重新上传" : "添加文件" }}</span>
      </div>
      <div class="el-upload__text">将文件拖到此处，或点击上传</div>
    </el-upload>

    <div class="el-upload__tip flex middle" v-if="$slots.tip">
      <slot name="tip" />
    </div>
  </mf-dialog>
</template>

<script>
export default {
  name: "MbImportDialog",

  data() {
    return {
      showList: false,
    };
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    title: String,

    onCancel: Function,

    onConfirm: Function,
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },

      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },

  methods: {
    handleChange(_, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }

      if (fileList.length > 0) {
        this.showList = true;
      } else {
        this.showList = false;
      }
    },

    getFiles() {
      return this.$refs["uploader"].uploadFiles;
    },

    getFirstFile() {
      return (this.getFiles() || [null])[0];
    },

    clearFiles() {
      this.$refs["uploader"].clearFiles();
      this.showList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-upload {
    width: 100%;

    &:focus {
      color: inherit;
      border-color: inherit;
    }
  }

  .el-upload-dragger {
    height: 220px;
    width: 100%;
    background: #f8f9fb;
    border-radius: 12px;
    border: 1px solid #e7eef8;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .el-upload:focus .el-upload-dragger {
    border-color: #e7eef8;
  }

  .list .el-upload-dragger {
    height: 180px;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .el-upload__tip {
    margin-top: 10px;
    font-size: 14px;
    color: #a7aaac;
  }

  .el-upload__text {
    margin-top: 15px;
    color: #6e777f;
  }

  .list .el-upload-list {
    height: auto;
    border-width: 1px;
    padding: 8px 10px;
    height: 40px;
    line-height: 40px;
  }

  .el-upload-list {
    height: 0;
    background: #f8f9fb;
    border: 0 solid #e7eef8;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-sizing: border-box;
  }

  .el-upload-list__item:first-child {
    margin-top: 0;
  }
}

.upload-text {
  .icon {
    color: #2969e3;
    height: 23px;
  }

  .text {
    margin-left: 10px;
    font-size: 20px;
    color: #000;
  }
}
</style>
