import Vue from 'vue'

import ImageUploader from './business/ImageUploader'
import FileUploader from './business/FileUploader'
import ImportDialog from './business/ImportDialog'
import LiveNotify from './business/LiveNotify/index.js'
import RoomNotify from './business/RoomNotify/index.js'


import AvatarBlock from './functional/AvatarBlock'
import DatePicker from './functional/DatePicker'
import Filter from './functional/Filter'
import FooterButton from './functional/FooterButton'
import Input from './functional/Input'
import OperationButton from './functional/OperationButton'
import Pagination from './functional/Pagination'
import Select from './functional/Select'
import StatusText from './functional/StatusText'
import Table from './functional/Table'
import Uploader from './functional/Uploader'
import TimeDown from './functional/TimeDown'
import Dialog from './functional/Dialog'
import Empty from './functional/Empty'
import Confirm from './functional/Confirm'
import VerificationInput from './functional/VerificationInput'



import EditPage from './layout/EditPage'
import TablePage from './layout/TablePage'
import TableView from './layout/TableView'

import ImageClipper from './extend/ImageClipper/index'

Vue.prototype.$liveNotify = LiveNotify;
Vue.prototype.$roomNotify = RoomNotify;


const modules = [
  ImageUploader,
  FileUploader,
  ImportDialog,
  AvatarBlock,
  DatePicker,
  Filter,
  FooterButton,
  Input,
  OperationButton,
  Pagination,
  Select,
  StatusText,
  Table,
  Uploader,
  TimeDown,
  Dialog,
  Empty,
  Confirm,
  VerificationInput,
  LiveNotify,
  RoomNotify,

  EditPage,
  TablePage,
  TableView,
]

const protos = [
  ImageClipper
]

modules.forEach(module => {
  Vue.component(module.name, module)
})

protos.forEach(proto => {
  Vue.prototype.$imageClipper = proto
})
