const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  user: state => state.user.user,
  imGroupId: state => state.user.imGroupId,
  userSignImg: state => state.user.userSignImg,
  introduction: state => state.user.introduction,
  roles: state => state.user.roleList,
  userEnterpriseWechat: state => state.user.userEnterpriseWechat,
  storeSetting: state => state.user.storeSetting,
  dynamicRouters: state => state.permission.dynamicRouters,
  errorLogs: state => state.errorLog.logs,
  dicts: state => state.dict.data,
  success: state => state.user.success,
  uploadUrl: state => state.user.uploadUrl,
  mode: state => state.user.mode,
  unReadMessageNum: state => state.notice.unReadMessageNum,
  isBrand: state => state.user.user.userType === '2',
  isStore: state => state.user.user.userType === '3',
  userPermission: state => state.user.user.storeAuth || {},
  updatePrice: state => storePermission(state, 'updatePrice'),
  createProduct: state => storePermission(state, 'createProduct'),
  createSerial: state => storePermission(state, 'createSerial'),
  singleAppoint: state => storePermission(state, 'singleAppoint'),
  realtimeCounsel: state => storePermission(state, 'realtimeCounsel'),
  realtimeStatus: state => state.user.realtimeStatus,
  channel: state => state.user.channel,
}

function storePermission(state, type) {
  const isStore = state.user.user.userType === '3'

  if (isStore && !(state.user.user.storeAuth || {})[type]) {
    return false
  }

  return true
}

export default getters
