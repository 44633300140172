import request from '@/utils/request'

// 查询系列列表
export function getSeriesList(params) {
  return request({
    url: '/series/getPage',
    method: 'get',
    params
  })
}

// 获取系列详情
export function getSeriesInfo(params) {
  return request({
    url: '/series/get',
    method: 'get',
    params
  })
}

// 获取所有商品
export function getAllProduct(params) {
  return request({
    url: '/series/getAllProduct',
    method: 'get',
    params
  })
}

// 新增修改系列
export function addSeries(data) {
  return request({
    url: '/series/createOrUpdate',
    method: 'post',
    data
  })
}

// 生成预约直播链接
export function createInviteLink(data) {
  return request({
    url: '/series/createInviteLink',
    method: 'post',
    data
  })
}

// 删除系列管理表
export function delSeries(data) {
  return request({
    url: '/series/delete',
    method: 'delete',
    data
  })
}

// 获取当前系列邀约海报信息
export function getInvitePost(params) {
  return request({
    url: '/series/getInvitePost',
    method: 'get',
    params
  })
}

// 批量设置可约商品-列表-增量
export function setBookingSeries(data) {
  return request({
    url: '/series/setBookingSeries',
    method: 'post',
    data
  })
}

// 批量设置可约商品-列表--自动删减
export function deleteBookingSeries(data) {
  return request({
    url: '/series/deleteBookingSeries',
    method: 'post',
    data
  })
}

// 保存商品邀约海报信息
export function updateInvitePost(data) {
  return request({
    url: '/series/updateInvitePost',
    method: 'post',
    data
  })
}

// 保存商品邀约海报信息
export function uploadSeriesTemp(data) {
  return request({
    url: '/series/uploadTemp',
    method: 'post',
    data
  })
}

// 批量系列上架
export function onsaleSeries(data) {
  return request({
    url: '/series/onsale',
    method: 'post',
    data
  })
}

// 批量商品下架
export function offsaleSeries(data) {
  return request({
    url: '/series/offsale',
    method: 'post',
    data
  })
}

// 系列排序
export function orderingSeries(data) {
  return request({
    url: '/series/orderingSeries',
    method: 'post',
    data
  })
}

// 系列商品排序
export function orderingSeriesProduct(data) {
  return request({
    url: '/series/orderingSeriesProduct',
    method: 'post',
    data
  })
}

// 新增修改系列商品
export function updateSeriesProduct(data) {
  return request({
    url: '/series/updateSeriesProduct',
    method: 'post',
    data
  })
}

// 查询系列管理表
export function getBookingPage(params) {
  return request({
    url: '/series/getBookingPage',
    method: 'get',
    params
  })
}

// 提交后保存最终序号
export function commitSeries(data) {
  return request({
    url: '/series/commit',
    method: 'post',
    data
  })
}

// 查询全部系列
export function getAllSeries(params) {
  return request({
    url: '/series/getAll',
    method: 'get',
    params
  })
}
