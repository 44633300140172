/**
 *  支持功能列表
 *  1、默认情况下只禁止空格输入 v-input
 *  2、限制只能输入整数 v-input-filter:int
 *  3、限制只能输入整数和小数（价格类） v-input-filter:price
 *  4、过滤特殊字符 v-input-filter:special
 *  5、限制只能输入手机号 v-input-filter:phone
 *  6、限制只能输入网址 v-input-filter:url
 */
const addListener = function (el, type, fn) {
  el.addEventListener(type, fn, false)
}

const spaceFilter = function (el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/\s+/, '')

    el.dispatchEvent(new Event('input'))
  })
}

const intFilter = function (el) {
  addListener(el, 'keyup', () => {
    let val = el.value.replace(/\D/g, '').slice(0, 8)

    if (!isNaN(parseInt(val))) {
      val = parseInt(val).toString()
    }

    el.value = val

    el.dispatchEvent(new Event('input'))
  })
}

const priceFilter = function (el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/[^\d.]*/g, '').slice(0, 14)
    if (isNaN(el.value)) {
      el.value = ''
    }

    el.dispatchEvent(new Event('input'))
  })
}
const specialFilter = function (el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
    el.dispatchEvent(new Event('input'))
  })
}
const accountFilter = function (el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/[^\w\-]/g, '')
    el.dispatchEvent(new Event('input'))
  })
}
const phoneFilter = function (el) {
  addListener(el, 'blur', () => {
    if (!el.value) {
      return
    }
    const phoneReg = new RegExp('^(13|14|15|16|17|18|19)[0-9]{9}$')
    if (!phoneReg.test(el.value)) {
      alert('手机号输入错误')
      el.value = ''
    }
    el.dispatchEvent(new Event('input'))
  })
}
const urlFilter = function (el) {
  addListener(el, 'blur', () => {
    if (!el.value) {
      return
    }
    const urlReg = /(^#)|(^http(s*):\/\/[^\s]+\.[^\s]+)/
    if (!urlReg.test(el.value)) {
      alert('url输入错误')
      el.value = ''
    }
    el.dispatchEvent(new Event('input'))
  })
}

function doBind(el, binding) {
  if (el.tagName.toLowerCase() !== 'input') {
    el = el.getElementsByTagName('input')[0]
  }
  spaceFilter(el)
  switch (binding.arg) {
    case 'int':
      intFilter(el)
      break
    case 'price':
      priceFilter(el)
      break
    case 'special':
      specialFilter(el)
      break
    case 'phone':
      phoneFilter(el)
      break
    case 'url':
      urlFilter(el)
      break
    case 'account':
      accountFilter(el)
      break
    default:
      break
  }
}

export default {
  bind(el, binding) {
    doBind(el, binding)
  },
  inserted(el, binding) {

  },
  update(el, binding) {

  },
  componentUpdated(el, binding) {

  },
  unbind(el, binding) {

  }
}
