import axios from 'axios'
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'

const _objToStrMap = (obj) => {
  const strMap = new Map()
  for (const k of Object.keys(obj)) {
    strMap.set(k, obj[k])
  }
  return strMap
}
/**
*json转换为map
*/
const _jsonToMap = (jsonStr) => {
  return _objToStrMap(JSON.parse(jsonStr))
}

const getDictMap = (key) => {
  const userInfo = JSON.parse(localStorage.getItem('access-user'))
  const dicts = userInfo.dicts
  const len = dicts.length
  for (var i = 0; i < len; i++) {
    if (dicts[i][0] == key) return dicts[i][1]
  }
  return []
}

const transType = (value, dicts) => {
  var len = dicts.length
  for (var i = 0; i < len; i++) {
    if (value == dicts[i].value) {
      return dicts[i].label
    }
  }
  return '未知'
}

const Format = function(date, fmt) { // author: meizz
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

// 截取文件名-不带后缀
function getFileName(text) {
  const len = text.lastIndexOf('.')
  const fileName = text.substring(0, len)
  return fileName
}
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

function formatDate(dateStr) {
  var newdateStr = dateStr.replace(/\d+(?=-[^-]+$)/, (a) => (parseInt(a, 10) - 1)) // 字符串处理(月份)
  var nums = newdateStr.match(/\d+/g) // 取数
  var date = eval('new Date(' + nums + ')') // 转换
  // 重组
  var datetime =
    date.getFullYear() + '-' + // 年
    ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) + '-' + // 月
    (date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())) // 日
  return datetime
}

// 两数组相减
function arrayDiff(a, b) {
  for (var i = 0; i < b.length; i++) {
    for (var j = 0; j < a.length; j++) {
      if (a[j] == b[i]) {
        a.splice(j, 1)
        j = j - 1
      }
    }
  }
  return a
}

function exportExcel(options = {}) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios({
      method: 'post',
      url: process.env.BASE_API + '/v1/file/excel/download', // 请求地址
      data: options, // 参数
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        })
        const fileName = options.fileName
        if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, fileName)
        } else {
          // console.log(3)
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          // 释放内存
          document.body.removeChild(link)
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}

const exportData = (options) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios.defaults.headers['token'] = getToken()
    axios({
      method: 'post',
      url: process.env.VUE_APP_BASE_API + options.url,
      data: options,
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)

        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, options.fileName)
        } else {
          // console.log(3)
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = options.fileName
          document.body.appendChild(link)
          link.click()
          // 释放内存
          document.body.removeChild(link)
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}

// 下载文件,文件名后端返回
const downloadFile = (option) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios.defaults.headers['Authorization'] = getToken()
    const url = option.url
    const param = option
    if (param.hasOwnProperty('url')) {
      delete param.url
    }
    axios({
      method: 'post',
      url: process.env.VUE_APP_BASE_API + url,
      data: param,
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
        //const fileName = decodeURIComponent(response.headers['content-disposition'].split('fileName=')[1])
        const fileName = param.name
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName)
        } else {
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          // 释放内存
          document.body.removeChild(link)
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        if (err.response.status == 500) {
          const errorMsg = decodeURIComponent(err.response.headers['error-msg'])
          Message({
            message: errorMsg,
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          reject(err)
        }
      }
    )
  })
}

// 下载图片&文件
const downloadImg = (url,name) => {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const fileUrl = URL.createObjectURL(blob)
          const eleLink = document.createElement('a')
          eleLink.download = name
          eleLink.href = fileUrl
          eleLink.click()
          eleLink.remove()
          // 用完释放URL对象
          URL.revokeObjectURL(fileUrl)
        })
      }
      resolve("ok")
    } catch {
      reject("err")
    }
  })
}

// 下载文件,文件名后端返回
const downloadExcelTemplate = (option) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios.defaults.headers['Authorization'] = getToken()
    axios({
      method: 'post',
      url: process.env.VUE_APP_BASE_API + "/api/live/brand/file/downloadExcelTemplate",
      data: option,
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
        //const fileName = decodeURIComponent(response.headers['content-disposition'].split('fileName=')[1])
        const fileName = option.name
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName)
        } else {
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          // 释放内存
          document.body.removeChild(link)
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        if (err.response.status == 500) {
          const errorMsg = decodeURIComponent(err.response.headers['error-msg'])
          Message({
            message: errorMsg,
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          reject(err)
        }
      }
    )
  })
}

// 数组去重，数组1排除数组2重复数据, 关键字段：id
function arrayDistinct(a, b) {
  var same = a.filter(i => b.map(m => { return m.id }).includes(i.id))
  if (same) {
    same.forEach((t, i, r) => {
      a.forEach((item, index, arr) => {
        if (item.id === t.id) {
          a.splice(index, 1)
        }
      })
    })
  }
  return a
}

function toArray(data) {
  if (data == null || data == '[]' || data == '') {
    return []
  }
  return eval('(' + data + ')')
}

/**
 * 数组对象去重
 * @param arr 数组
 * @param key 去重关键字
 * @returns {*}
 */
function unique(arr, key) {
  var obj = {}
  arr = arr.reduce(function(item, next) {
    obj[next[key]] ? '' : obj[next[key]] = true && item.push(next)
    return item
  }, [])
  return arr
}

function nowDate() {
  return dateFormat('YYYY-mm-dd', new Date())
}

function nowDateTime() {
  return dateFormat('YYYY-mm-dd HH:MM:SS', new Date())
}

function dateFormat(fmt, date) {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

export default {
  _objToStrMap,
  _jsonToMap,
  getDictMap,
  transType,
  Format,
  getFileName,
  parseTime,
  formatTime,
  arrayDiff,
  exportExcel,
  exportData,
  downloadFile,
  downloadImg,
  downloadExcelTemplate,
  arrayDistinct,
  toArray,
  unique,
  nowDate,
  nowDateTime,
  dateFormat,
  formatDate
}
