<template>
  <div class="text" :class="{ light: light || record.type === 'light' }">
    <span
      class="dot"
      :style="{ backgroundColor: color || record.color || '' }"
    ></span>
    <span>{{ label || record.label || record.title }}</span>
  </div>
</template>

<script>
export default {
  name: "MfStatusText",

  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    label: String,
    light: Boolean,
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.text {
  display: inline-block;

  .dot {
    margin-right: 5px;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #46bc95;
    vertical-align: middle;
  }

  &.light {
    color: #6e777f;

    .dot {
      background: #bec9d2;
    }
  }
}
</style>
