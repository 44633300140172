<template>
  <el-button v-bind="$attrs" v-on="$listeners" size="medium">
    <slot />
  </el-button>
</template>

<script>
export default {
  name: "MfOperationButton",
};
</script>

<style lang="scss" scoped>
.el-button {
  height: 40px;
  border-radius: 12px;
  padding: 10px 15px;
  color: #2969e3;
  border: none;
  background-color: #F2F7FF;
}
</style>
