<template>
  <el-pagination
    :current-page.sync="page"
    :page-size.sync="size"
    :total="total"
    :layout="layout"
    background
    v-on="$listeners"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "MfPagination",

  props: {
    currentPage: {
      required: true,
      type: Number,
    },

    PageSize: {
      required: true,
      type: Number,
    },

    total: {
      required: true,
      type: Number,
    },

    layout: {
      type: String,
      default: 'sizes, jumper, prev, pager, next'
    },
  },

  computed: {
    page: {
      get() {
        return this.currentPage;
      },

      set(val) {
        this.$emit("update:current-page", val);
      },
    },

    size: {
      get() {
        return this.PageSize;
      },

      set(val) {
        this.$emit("update:page-size", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination ::v-deep {
  span.el-pagination__sizes {
    margin: 0 10px;
    width: 116px;
    height: 32px;
    line-height: 32px;
    background: #f8f9fa;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;

    input {
      background: transparent;
      border: 0;
    }

    .el-select__caret {
      color: #6e777f;
    }
  }

  span.el-pagination__jump {
    margin: 0 10px;
    width: 137px;
    height: 32px;
    line-height: 32px;
    background: #f8f9fa;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 12px 12px;
    text-align: center;
    border: 1px solid #f5f5f5;

    .el-input {
      width: 60px;
      padding: 0 10px;

      input.el-input__inner {
        height: 32px;
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
        border-color: #eff0f2;
        background: transparent;
      }
    }
  }

  .btn-prev {
    margin: 0;
    margin-left: 10px;
    height: 32px;
    border-radius: 12px 0 0 12px;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }

  .el-pager {
    background-color: #f5f5f5;
    box-shadow: 3px 1px 2px 1px rgba(0, 0, 0, 0.1);

    .number {
      margin: 0;
      min-width: 26px;
      height: 32px;
      line-height: 32px;
      border-radius: 0;
      background-color: #f5f5f5;
    }
  }

  .btn-next {
    margin: 0;
    height: 32px;
    border-radius: 0 12px 12px 0;
    background-color: #f5f5f5;
    box-shadow: 3px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }
}
</style>
