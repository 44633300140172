<template>
  <div class="table-page full-page" :class="isHasChannel ? 'table-page-channel' : ''">
    <div
      v-if="$slots.search"
      class="search-container flex"
      :style="{
        height: searchHeight + 'px',
      }"
      ref="searchContainer"
    >
      <slot name="search" />

      <el-button
        v-if="toggleShown"
        type="text"
        class="toggle-button"
        @click="toggleSearchContainer"
      >
        {{ showMoreSearch ? "收起更多" : "展开更多"
        }}<i
          :class="`${
            showMoreSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
          } el-icon--right`"
        ></i>
      </el-button>
    </div>

    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div class="table-container">
        <slot />
      </div>
    </el-scrollbar>

    <div class="footer-container" v-if="$slots.footer && showFooter">
      <slot name="footer" />
    </div>

    <slot name="dialog" />
  </div>
</template>

<script>
const SEARCH_HEIGHT = 110;

export default {
  name: "MlTablePage",

  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    isHasChannel: { //当前页面是否有渠道
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      toggleShown: true,
      showMoreSearch: false,
      searchHeight: SEARCH_HEIGHT,
    };
  },

  mounted() {
    this.calcToggleShown();

    window.addEventListener("resize", this.calcToggleShown);
  },

  destroyed() {
    window.removeEventListener("resize", this.calcToggleShown);
  },

  methods: {
    toggleSearchContainer() {
      if (this.showMoreSearch) {
        this.searchHeight = SEARCH_HEIGHT;
      } else {
        this.searchHeight = this.$refs.searchContainer.scrollHeight;
      }

      this.showMoreSearch = !this.showMoreSearch;
    },

    calcToggleShown() {
      const el = this.$refs["searchContainer"];

      if (el) {
        this.toggleShown = el.scrollHeight > SEARCH_HEIGHT * 1.5;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.table-page-channel {
  height: calc(100vh - 128px) !important;
}

.search-container {
  flex: 0 0 auto;
  padding: 10px 24px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
  align-items: flex-start;
  box-sizing: border-box;
  transition: height 0.25s;
  overflow: hidden;
  position: relative;
  z-index: 100;

  .el-form {
    flex: 1;
  }
}

.table-container {
  flex: 1;
  padding: 32px 24px;
  padding-bottom: 64px;
  overflow: auto;
}

.footer-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
  box-shadow: 10px -2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 10px 20px;
}

.toggle-button {
  margin-top: 40px;
  margin-left: 5px;
}

.search-container ::v-deep {
  .el-form-item__label {
    color: #6e777f;
    font-weight: normal;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0;
  }

  .el-form-item {
    margin-right: 24px;
    margin-bottom: 10px;
  }
}

.openSidebar .footer {
  left: 188px;
}
</style>
