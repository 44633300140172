import request from '@/utils/request'

export function login(data) {
  return request({
    url: `/login`,
    method: 'post',
    data
  })
}

export function getUserInfo() {
  return request({
    url: `/getInfo`,
    method: 'get'
  })
}

export function getRouters() {
  return request({
    url: `/getRouters`,
    method: 'get'
  })
}

export function logout() {
  return request({
    url: `/logout`,
    method: 'post'
  })
}
