<template>
  <div :class="classObj" class="app-wrapper">
    <sidebar class="sidebar-container" />

    <headbar class="headbar-container" />

    <app-main class="main-container" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResizeMixin from "./mixin/ResizeHandler";
import { Sidebar, AppMain, Headbar } from "./components";

export default {
  name: "Layout",

  mixins: [ResizeMixin],

  computed: {
    ...mapState("app", ["sidebar"]),

    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      };
    },
  },

  components: {
    Sidebar,
    AppMain,
    Headbar,
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  &.hideSidebar {
    .headbar-container {
      left: 54px;
    }
  }

  .sidebar-container {
    padding-bottom: 40px;
  }

  .headbar-container {
    position: fixed;
    top: 0;
    left: 188px;
    right: 0;
    z-index: 99;
    transition: left 0.28s;
  }

  .main-container {
    padding-top: 48px;
    // height: 100vh;
    background: #fff;

    ::v-deep .full-page {
      height: calc(100vh - 48px);
      box-sizing: border-box;
    }
  }
}
</style>
