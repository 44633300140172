import request from '@/utils/request'

// 新增/修改商品
export function addProduct(data) {
  return request({
    url: '/product/createOrUpdate',
    method: 'post',
    data
  })
}

// 删除商品
export function delProduct(data) {
  return request({
    url: '/product/delete',
    method: 'delete',
    data
  })
}

// 获取商品详情
export function getProduct(params) {
  return request({
    url: '/product/getForEdit',
    method: 'get',
    params
  })
}

// 获取商品详情
export function getProductInfo(params) {
  return request({
    url: '/product/get',
    method: 'get',
    params
  })
}

// 获取系统设置的邀约海报信息
export function getInvitePost(params) {
  return request({
    url: '/product/getInvitePost',
    method: 'get',
    params
  })
}

// 获取全部商品列表
export function getAllProductList(params) {
  return request({
    url: '/product/getList',
    method: 'get',
    params
  })
}

// 获取商品列表
export function getProductList(params) {
  return request({
    url: '/product/getPage',
    method: 'get',
    params
  })
}

// 上传图片到临时文件-定时清空
export function uploadProductTemp(data) {
  return request({
    url: '/product/uploadTemp',
    method: 'post',
    data
  })
}

// 批量商品上架
export function onsaleProduct(data) {
  return request({
    url: '/product/onsale',
    method: 'post',
    data
  })
}

// 批量商品下架
export function offsaleProduct(data) {
  return request({
    url: '/product/offsale',
    method: 'post',
    data
  })
}

// 批量设置可约商品-列表-增量
export function setBookingProduct(data) {
  return request({
    url: '/product/setBookingProduct',
    method: 'post',
    data
  })
}

// 批量设置可约商品-弹窗-自动删减
export function updateBookingProduct(data) {
  return request({
    url: '/product/updateBookingProduct',
    method: 'post',
    data
  })
}

// 生成预约直播链接
export function createInviteLink(data) {
  return request({
    url: '/product/createInviteLink',
    method: 'post',
    data
  })
}

// 保存商品邀约海报信息
export function updateInvitePost(data) {
  return request({
    url: '/product/updateInvitePost',
    method: 'post',
    data
  })
}


// 修改价格
export function editPrice(data) {
  return request({
    url: '/product/editPrice',
    method: 'post',
    data
  })
}

