import { Loading } from 'element-ui'

let gloableLoading
function start(title) {
  gloableLoading = Loading.service({
    text: title === null ? '拼命加载中...' : title,
    spinner: 'el-icon-loading',
    background: 'rgba(100, 100, 100, 0.7)'
  })
}

function close() {
  if (gloableLoading) {
    gloableLoading.close()
  }
}

export default {
  start,
  close
}
