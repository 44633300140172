import request from '@/utils/request'

// im初始化-获取token
const initTxIm = (params) => {
  return request({
    url: `/txIm/init`,
    method: 'get',
    params
  })
}

// im历史消息记录
const chattingList = (params) => {
  return request({
    url:`/txIm/msg/getPage`,
    method:'get',
    params
  })
}

// 私聊发送普通消息
const sendMessage = (data) => {
  return request({
    url: `/txIm/sendMsg`,
    method: 'post',
    data
  })
}

// 群组内发送普通消息
const sendGroupMsg = (roomId, msgType, msg, id,seriesId = '') => {
  return request({
    url: `/api/live/brand/im/send-group-msg`,
    method: 'post',
    data: { roomId: roomId, msgType: msgType, msg: msg, id: id, admin: true,seriesId:seriesId }
  })
}

/* 查询直播私信用户列表 */
const getUserList = (data) => {
  return request({
    url: `/api/live/brand/im/findLiveUserList`,
    method: 'post',
    data
  })
}

/* 查询直播用户私信记录 */
const getUserMessageList = (data) => {
  return request({
    url: `/api/live/brand/im/find-c2c-msg-his`,
    method: 'post',
    data
  })
}

/* 获取直播间所有未读消息数量 */
const getNoReplyCount = (data) => {
  return request({
    url:`/api/live/brand/im/getNoReplyCount`,
    method:'post',
    data
  })
}
// 当发生聊天窗口切换时 change-chatting-tab
const chattingTab = (data) => {
  return request({
    url:`/api/live/brand/im/changeChattingTab`,
    method:'post',
    data
  })
}

/* 查询群聊记录 */
const getGroupMsgList = (data) => {
  return request({
    url: `/api/live/brand/im/find-group-msg-his`,
    method: 'post',
    data
  })
}

export default {
  initTxIm,
  chattingList,
  sendMessage,
  sendGroupMsg,
  getUserList,
  getUserMessageList,
  getNoReplyCount,
  // changeImTab,
  chattingTab,
  getGroupMsgList
}
