import Cookies from 'js-cookie'

const TokenKey = 'brand_admin_token'
const UserKey = 'brand_admin_user'
const layoutKey = 'brand_admin_layout'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setUser(user) {
  return localStorage.setItem(UserKey, JSON.stringify(user))
}

export function getUser() {
  return !localStorage.getItem(UserKey) ? '' : JSON.parse(localStorage.getItem(UserKey))
}

export function removeUser() {
  return localStorage.removeItem(UserKey)
}

export function getLayout() {
  return Cookies.get(layoutKey)
}

export function setLayout(token) {
  return Cookies.set(layoutKey, token)
}
