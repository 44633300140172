<template>
  <div>
    <div v-show="progress" style="height: 40px" class="flex middle">
      <el-progress
        :percentage="progress"
        :show-text="false"
        style="width: 240px"
      ></el-progress>

      <el-button style="opacity: 0"></el-button>
    </div>

    <div v-show="value && !progress" class="uploaded">
      <slot name="uploaded" v-if="shownName">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86 3C6.07145 3 3 6.07145 3 9.86C3 13.6485 6.07145 16.72 9.86 16.72C13.6485 16.72 16.72 13.6485 16.72 9.86C16.72 6.07145 13.6485 3 9.86 3ZM7.57422 7.23289C7.57422 6.72982 7.93231 6.52036 8.37318 6.76321L13.1848 9.41894C13.6257 9.6627 13.6247 10.0578 13.1848 10.3011L8.37318 12.9569C7.93185 13.2002 7.57422 12.9866 7.57422 12.4872V7.23289Z" fill="#2C66FF"/>
        </svg>
        <span style="margin: 0 20px 0 5px">{{ shownName }}</span>
      </slot>

      <slot name="operation" :modify="modifyFile">
        <el-button
          type="text"
          class="no-padding"
          icon="el-icon-refresh"
          @click="modifyFile"
          >修改</el-button
        >

        <el-divider direction="vertical"></el-divider>

        <el-button
          type="text"
          class="no-padding black"
          icon="el-icon-close"
          @click="removeFile"
          >删除</el-button
        >
      </slot>
    </div>

    <mf-uploader
      ref="fileUploader"
      v-show="!value && !progress"
      type="product"
      :accept="accept"
      :size-limit="sizeLimit"
      :before-upload="beforeUpload"

      @success="uploadSuccess"
      @error="uploadError"
    >
      <slot>
        <mf-operation-button>
          <svg-icon icon-class="icon-import" />
          上传视频
        </mf-operation-button>
      </slot>
    </mf-uploader>

    <div class="tip" v-if="tip">{{ tip }}</div>
  </div>
</template>

<script>
export default {
  name: "MbFileUploader",

  props: {
    value: String,
    tip: String,
    accept: String,
    sizeLimit: {
      type: Number,
      default: 30,
    },
    fileName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      progress: 0,
    };
  },

  computed: {
    shownName() {
      let name = this.fileName;

      if (name.length > 15) {
        name = name.slice(0, 12) + "..." + name.slice(-4);
      }

      return name;
    },
  },

  methods: {
    beforeUpload() {
      this.progress = 1;

      clearInterval(this.progressInterval);
      this.progressInterval = setInterval(() => {
        if (this.progress < 95) {
          this.progress += 5;
        }

        if (this.progress >= 100) {
          clearInterval(this.progressInterval);

          setTimeout(() => {
            this.progress = 0;
          }, 500);
        }
      }, 100);
    },

    uploadSuccess(info = {}, name = "") {
      this.$emit("success", {
        ...info,
        name
      });
      this.progress = 100;
    },

    removeFile() {
      this.$emit('remove')
    },

    uploadError() {
      this.progress = 100;
    },

    modifyFile() {
      this.$refs.fileUploader.$children[0].$children[0].$refs.input.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  color: #a7aaac;
  line-height: 1.5;
  margin-top: 8px;
}
.uploaded {
  display: flex;
  align-items: center;
}
</style>
