export function asyn(promise, errorExt) {
  return promise
    .then(function(data) {
      return [null, data]
    })
    .catch(function(err) {
      if (errorExt) {
        Object.assign(err, errorExt)
      }
      return [err, undefined]
    })
}
