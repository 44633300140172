<template>
  <el-select ref="input" v-model="input" v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-select>
</template>

<script>
export default {
  name: "MfSelect",

  props: {
    value: {
      type: String,
      default: "",
    },

    tabIndex: String,
  },

  computed: {
    input: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  mounted() {
    this.setTabIndex();
  },

  updated() {
    this.setTabIndex();
  },

  methods: {
    setTabIndex() {
      if (this.tabIndex) {
        this.$refs.input.$el.querySelectorAll("input").forEach((item) => {
          this.$nextTick(() => {
            item.setAttribute("tabindex", this.tabIndex);
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 344px;
  height: 40px;

  ::v-deep {
    input {
      background: #f8f9fa;
      border-color: #f5f5f5;
      border-radius: 12px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      &:hover,
      &:focus {
        border-color: #ccc;
      }
    }

    .el-input.is-focus input {
      border-color: #ccc;
    }
  }
}
</style>
