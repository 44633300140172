import store from '@/store'

export default function (el, binding) {
  const arg = binding.arg;
  const value = binding.modifiers.hide ? 'hide' : 'show';
  const userType = 'brand' || store.getters.user.userType.toString();

  switch (arg) {
    case 'brand':
      if ((value === 'show' && userType !== '2') ||
        (value === 'hide' && userType === '2')) {
        removeElement(el)
      }
      break;
    case 'store':
      if ((value === 'show' && userType !== '3') ||
        (value === 'hide' && userType === '3')) {
        removeElement(el)
      }
      break;
    case 'inspector':
      const isBoss = store.getters.user.boss && userType === '3';
      if ((value === 'show' && !isBoss) ||
        (value === 'hide' && isBoss)) {
        removeElement(el)
      }
      break;
  }
}

function removeElement(el) {
  el.style.display = 'none';
  el.parentNode && el.parentNode.removeChild(el);
}
