<template>
  <el-table
    ref="table"
    :data="data"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-table>
</template>

<script>
export default {
  name: "MfTable",

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clearSelection() {
      this.$refs.table.clearSelection()
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-table__header {
    border-radius: 10px;
    border: 0;
    overflow: hidden;

    th {
      background: #f5f5f5;
      color: #000;
    }
  }
}
</style>
