import Vue from 'vue'
import { login, getUserInfo, logout } from '@/api/login'
import im from "@/api/im/im";
import { getToken, setToken, removeToken, setLayout } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const user = {
  state: {
    success: 200,
    token: getToken(),
    avatar: '',
    name: '',
    user: {},
    realtimeStatus: '1',
    imGroupId: '',
    // 当前用户是否有企业微信的权限
    userEnterpriseWechat: true,
    // 门店设置
    storeSetting: {},
    // 微信 | 京东 | 抖音
    channel: 'wx',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_IM_GROUP_ID: (state, imGroupId) => {
      state.imGroupId = imGroupId
    },
    SET_REALTIME_STATUS: (state, status) => {
      state.realtimeStatus = status
    },
    SET_USER_ENTERPRISEWECHAT: (state, data) => {
      state.userEnterpriseWechat = data
    },
    SET_STORE_SETTING: (state, data) => {
      state.storeSetting = data
    },
    SET_CHANNEL: (state, data) => {
      state.channel = data
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          // if (res.body.isLocked) {
          //   let message = '登录失败，请重试'

          //   if (res.body.lockExpireTime) {
          //     message = `账号密码输入错误次数过多已被锁定，请1分钟后重试`
          //   } else if (res.body.reqTime) {
          //     message = `验证失败次数超过限制，请${body.reqTime > 60 ? parseInt(body.reqTime / 60) : 1}分钟之后重试！`
          //   }

          //   MessageBox.alert(message, '提示', {
          //     confirmButtonText: '我知道了',
          //     type: 'info'
          //   })

          //   reject()
          // }

          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve(res)
        }).catch(error => {
          console.log('@@@@@@@', error)
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(data => {
          data.user.id = data.user.userId
          im.initTxIm().then(({ data }) => {
            Vue.prototype.tim.login({
              userID: data.account,
              userSig: data.sign
            })
          })
          commit('SET_USER', data.user)
          commit('SET_NAME', data.user.userName)
          commit('SET_AVATAR', '')
          // setLayout(data.layout)
          resolve(data.user)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // remove token
    setImGroupId({ commit }, imGroupId) {
      return new Promise(resolve => {
        commit('SET_IM_GROUP_ID', imGroupId)
      })
    },

    // 登出
    LogOut({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_USER', {})

          removeToken()
          resetRouter()

          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch('tagsView/delAllViews', null, { root: true })

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // remove token
    ResetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USER', [])
        removeToken()
        resolve()
      })
    },

    // dynamically modify permissions
    changeRoles({ commit, dispatch }, role) {
      return new Promise(async resolve => {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const { roles } = await dispatch('getInfo')

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USER', {})
        commit('SET_NAME', '')
        commit('SET_AVATAR', '')
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        removeToken()
        resolve()
      })
    }

  }
}

export default user
