import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element, {Message} from 'element-ui'
import './directive/index'
import i18n from '@/locale/i18n'
import App from './App'
import store from './store'
import router from './router'

import './modules'
import './plugins'
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import util from './plugins/util'
import { getLayout } from '@/utils/auth'
import { dictText, dictData } from './plugins/dictionary'
import { asyn } from './plugins/promise'
import { Loading } from 'element-ui'
import moment from 'moment' // 导入文件
// 洽太管理后台埋点
import ctail from './utils/vue-track.es6.js'
// 埋点方法调用
import { trackFun } from './utils/track.js'

Vue.use(Element)
// Vue.use(i18n)
// import locale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(Element, {
  // size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false

Vue.prototype.util = util
Vue.prototype.dictText = dictText
Vue.prototype.dictData = dictData
Vue.prototype.asyn = asyn
Vue.prototype.$loading = Loading
Vue.prototype.Message = Message
Vue.prototype.$moment = moment
// 洽太管理后台埋点
Vue.prototype.ctail = ctail
// 埋点方法调用
Vue.prototype.trackFun = trackFun
Vue.prototype.$store = store

// 全局引入IM

import { tim, TIM } from './utils/im'
Vue.prototype.TIM = TIM;
Vue.prototype.tim = tim;
import bus from './utils/im/bus'
Vue.prototype.$bus = bus

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
