import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import loading from '@/plugins/loading'

axios.defaults.withCredentials = false// 允许跨域携带cookie

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // loading.start()
    if (getToken()) config.headers['Authorization'] = getToken()

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    loading.close()
    const res = response.data

    if (String(res) == '[object Blob]') {
      return res
    }
    if (response.status.toString() === '401') {
      store.dispatch('LogOut').then(() => {
        location.reload()
      })

      return Promise.reject('error')
    }
    if (res.code !== store.getters.success) {
      if (res.code === 401) {
        store.dispatch('LogOut').then(() => {
          location.reload()
        })
      } else if ([10002, 10003, 10004, 20000].includes(res.code)) {
        return Promise.reject(res)
      } else {
        Message({
          message: res.msg || '未知错误',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(res.respHeader)
    } else {
      return response.data
    }
  },
  error => {
    loading.close()
    let authErr = false
    if (error.response.status === 401) {
      authErr = true
      MessageBox.alert('您已被登出或Token已失效，请重新登录', '提示', {
        confirmButtonText: '重新登录',
        callback: action => {
          store.dispatch('ResetToken').then(() => {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }
      })
    } else if (error.response.status === 403) {
      authErr = true
      Message({
        message: '权限不足',
        type: 'error',
        duration: 5 * 1000
      })
    } else if (error.response.status === 504) {
      authErr = true
      Message({
        message: '网络异常，请稍候重试',
        type: 'error',
        duration: 5 * 1000
      })
    }
    if (!authErr) {
      Message({
        message: (error.response.data.respHeader || {}).respMsg || '未知错误',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error.response.respHeader)
  }
)

export default service
