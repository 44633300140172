export default function (blob, filename) {
  blob = new Blob([blob], {
    type: "application/vnd.ms-excel",
  });

  const objectUrl = URL.createObjectURL(blob);
  const el = document.createElement("a");
  el.setAttribute("href", objectUrl);
  el.setAttribute("download", `${filename}.xls`);
  el.click();
  el.remove();
}
