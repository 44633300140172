import store from '@/store'

/**
 * 数据字典
 * @param cellValue
 * @param dictCode
 * @returns {*}
 */
export function dictText(cellValue, dictCode) {
  if (!cellValue) return ''
  if ((typeof cellValue) === 'string') {
    cellValue = cellValue.replaceAll('"', '').replaceAll('[', '').replaceAll(']', '')
  }
  const dicts = store.getters.dicts
  if (JSON.stringify(dicts) === '{}') {
    return cellValue
  }
  const dict = dicts['' + dictCode + '']
  if (!dict) return cellValue
  const target = dict.filter((item) => { return item.value === cellValue })
  if (target.length > 0) {
    return target[0].label
  }
  return cellValue
}

/**
 * 获取特定key字典集合数据
 * @param key
 * @returns {Array}
 */
export function dictData(key) {
  const target = this.$store.getters.dicts[key]
  if (target) {
    return target
  } else {
    return []
  }
}

/**
 * 数据字典去除
 * @param arr
 */
export function unique(arr) {
  const map = new Map()
  return arr.filter((arr) => (arr.value != null && arr.value !== '' && !map.has(arr.value)) && map.set(arr.value, 1))
}
