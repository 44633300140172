import $bus from './bus'
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import { MessageBox } from 'element-ui'
import store from '@/store'
import router from '../../router'

let options = {
  SDKAppID: 1600046732
};
let tim = TIM.create(options);
tim.setLogLevel(1);
tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});

tim.on(TIM.EVENT.MESSAGE_RECEIVED, (res) => {
  if (res && res.data.length) {
    const message = JSON.parse(res.data[0].payload.text)
    console.log('IM---------', message)
    if (message.msgType === "PUSH") {
      // 预约通知
      const appointType = [
        'BOOK_CREATE_SERIES', // 1v1系列预约成功
        'BOOK_CREATE_PRODUCT', // 1v1单品预约成功
        'BOOK_CANCEL_SERIES', // 1v1系列预约取消
        'BOOK_CANCEL_PRODUCT', // 1v1单品预约取消
        'LIVE_START_SERIES', // 1v1预约直播开始
        'LIVE_START_PRODUCT' // 1v1单品直播开始
      ]
      if (appointType.includes(message.pushType)) {
        message.live = message.live.replace(/\"id":(\d+)/g,'"id": "$1"')
        $bus.$emit('getLivePush', JSON.parse(JSON.stringify(message)))
      }
      // 直播间通知
      const roomType = [
        'CREATE_ORDER', // 下单
        'MY_LIKE', // 咨询此件
        'ADD_CART', // 加购
      ]
      if (roomType.includes(message.pushType)) {
        $bus.$emit('getRoomPush', JSON.parse(JSON.stringify(message)))
      }
      // 关闭直播间推送
      if (message.pushType === 'USER_CLOSE_LIVE') {
        $bus.$emit('userCloseLive', JSON.parse(JSON.stringify(message)))
      }
    } else {
      $bus.$emit('getMessageReceive', JSON.parse(JSON.stringify(message)))
    }
    if (message.pushType === "LOGOUT") {
      MessageBox.alert('web账号被挤掉线后，弹出提示弹窗，确认后应该退出当前登录返回登录页面。', '提示', {
        confirmButtonText: '确认',
        callback: action => {
          store.dispatch('ResetToken').then(() => {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            router.push(`/login`);
          })
        }
      })
    }
  }
})

export {
  TIM,
  tim
}
